import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header/Header";
import { toast } from "react-toastify";

const LegalPage = ({ fileName }) => {
  const [htmlContent, setHtmlContent] = useState("");
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(`/legal-pages/${fileName}`);
        if (response.status !== 200) {
          toast.info(response.data.msg);
        }
        const html = await response.text();
        setHtmlContent(html);
      } catch (error) {
        console.error("Error fetching HTML content:", error);
        toast.error("Error Fetching Data!")
      }
    };

    fetchHtmlContent();
  }, [fileName]);

  useEffect(() => {
    if (contentRef.current && htmlContent) {
      contentRef.current.innerHTML = htmlContent;
    }
  }, [htmlContent]);

  return (
    <>
      <div className="header">
        <Header />
      </div>
      <div
        ref={contentRef}
        style={{
          margin: "10px",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        Loading...
      </div>
    </>
  );
};

export default LegalPage;
