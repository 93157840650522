import React, { useContext, useEffect, useState } from "react";
import "./authorPreference.css";
import axios from "axios";
import { Context } from "../../../context/Context";
import { toast } from "react-toastify";

const AuthorPreference = () => {
  const [users, setUsers] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const { user, token } = useContext(Context);

  useEffect(() => {
    const cards = document.querySelectorAll(".card");

    const toggleCardSelection = (event) => {
      event.currentTarget.classList.toggle("selected");
    };

    cards.forEach((card) => {
      card.addEventListener("click", toggleCardSelection);
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("click", toggleCardSelection);
      });
    };
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axios.get("/users");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const authors = res.data.filter((user) => user.isAuthor);
        setUsers(authors);
      } catch (err) {
        console.error("Error fetching writers! " + err);
        toast.error("Error Fetching Writers!");
      }
    };
    const getUserAuthors = async () => {
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setSelectedAuthors(res.data.authors);
      } catch (err) {
        console.error("Error Fetching User Writers! " + err);
        toast.error("Error Fetching Favorite Writers!");
      }
    };
    getUsers();
    getUserAuthors();
  }, []);

  const toggleAuthorSelection = (authorId) => {
    // Check if the author is already selected
    const index = selectedAuthors.indexOf(authorId);
    if (index !== -1) {
      // If selected, remove it from the selected authors
      setSelectedAuthors((prevAuthors) =>
        prevAuthors.filter((id) => id !== authorId)
      );
    } else {
      // If not selected, add it to the selected authors
      setSelectedAuthors((prevAuthors) => [...prevAuthors, authorId]);
    }
  };

  const handleSaveAuthors = async () => {
    try {
      await axios.put(`/users/${user._id}`, {
        authors: selectedAuthors,
      }, {headers: {'Authorization':`Bearer ${token}`}});
      toast.success("Updated!");
    } catch (error) {
      toast.error("Error Updating Preferences");
    }
  };

  const sortedUsers = [...users].sort((a, b) => {
    const isASelected = selectedAuthors.includes(a.userId);
    const isBSelected = selectedAuthors.includes(b.userId);
    if (isASelected && !isBSelected) return -1;
    if (!isASelected && isBSelected) return 1;
    return 0;
  });

  return (
    <div className="author-preferences" id="author-preferences">
      {/* Container for author preferences cards */}

      <div className="heading-class-author">
        <h1
          className="heading-author"
          style={{ fontSize: "24px", fontWeight: "400" }}
        >
          Favorite Writers
        </h1>
        <h2
          className="subheading-author"
          style={{ fontSize: "20px", fontWeight: "400" }}
        >
          Select all that apply
        </h2>
      </div>

      <div className="card-container-author sidebarScrollable">
        {/* First row of cards */}

        {sortedUsers.map((user) => (
          <div
            key={user.userId}
            className="card-wrapper"
            onClick={() => toggleAuthorSelection(user.userId)}
          >
            <div
              className={`card ${
                selectedAuthors.includes(user.userId) ? "selected" : ""
              }`}
            >
              <div className="card-inner">
                <div
                  className="card-front"
                  style={{
                    background:
                      user && user.avatar_urls["full"] == null
                        ? `url(${user.profilePic + "&size=150"})`
                        : `url(${
                            process.env.REACT_APP_AWS_S3_IMAGE_URL + user.avatar_urls["150"]
                          })`,
                  }}
                ></div>
                <div className="card-back">
                  <p>Followings: {user.followings.length}</p>
                  <p>Followers: {user.followers.length}</p>
                </div>
              </div>
            </div>
            <h3 className="author-name">{user.user_name}</h3>
          </div>
        ))}
      </div>
      <button
        type="submit"
        className="save-button"
        style={{
          width: "212px",
          height: "67px",
          marginLeft: "550px",
          marginTop: "30px",
          fontSize: "28px",
        }}
        onClick={handleSaveAuthors}
      >
        Save
      </button>
    </div>
  );
};

export default AuthorPreference;
