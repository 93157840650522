import { createContext, useEffect, useReducer } from "react";
import { jwtDecode } from "jwt-decode";
import Reducer from "./Reducer";
import React from "react";

const getUserFromLocalStorage = () => {
  try {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error.message);
    return null;
  }
};

const getTokenFromLocalStorage = () => {
  try {
    const token = localStorage.getItem("token");
    return token ? JSON.parse(token) : null;
  } catch (error) {
    console.error("Error parsing token from localStorage:", error.message);
    return null;
  }
};

const INITIAL_STATE = {
  user: getUserFromLocalStorage(),
  isFetching: false,
  uniqueArticleIds: new Set(),
  token: getTokenFromLocalStorage(),
};

export const Context = createContext(INITIAL_STATE);

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.user));
  }, [state.user]);

  //Set token in localStorage
  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(state.token));
  }, [state.token]);

  useEffect(() => {
    if (state.token) {
      const decodedToken = jwtDecode(state.token);
      const expTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      const timeout = expTime - currentTime;

      if (timeout > 0) {
        const logoutTimer = setTimeout(() => {
          dispatch({ type: "LOGOUT" });
        }, timeout);

        return () => clearTimeout(logoutTimer);
      } else {
        dispatch({ type: "LOGOUT" });
      }
    }
  }, [state.token, dispatch]);

  const handleViewIncrement = (articleId) => {
    dispatch({ type: "INCREMENT_USER_VIEWS", payload: { articleId } });
  };

  return (
    <Context.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        userViews: state.userViews,
        token: state.token,
        dispatch,
        handleViewIncrement,
      }}
    >
      {children}
    </Context.Provider>
  );
};

// import React, { createContext, useEffect, useReducer } from "react";
// import Reducer from "./Reducer";
// import { IncrementUserViews } from "./Actions";

// const INITIAL_STATE = {
//   user: JSON.parse(localStorage.getItem("user")) || null,
//   isFetching: false,
//   error: false,
//   userViews: 0,
//   uniqueArticleIds: new Set(),
// };

// export const Context = createContext(INITIAL_STATE);

// export const ContextProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

//   useEffect(() => {
//     localStorage.setItem("user", JSON.stringify(state.user));
//   }, [state.user]);

//   useEffect(() => {
//     if (state.userViews >= 5 && !state.user) {
//       alert("Your free access is over. Sign in to get unlimited access.");
//     }
//   }, [state.userViews, state.user]);

//   const handleViewIncrement = (articleId) => {
//     dispatch(IncrementUserViews());
//     dispatch({ type: "ADD_UNIQUE_ARTICLE_ID", payload: articleId });
//   };

//   return (
//     <Context.Provider
//       value={{
//         ...state,
//         dispatch,
//         handleViewIncrement,
//       }}
//     >
//       {children}
//     </Context.Provider>
//   );
// };
