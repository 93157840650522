import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Dialog from "../dialogBox/dialogBox"; 
import "./footer.css";

export default function Footer() {
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const openAboutUsDialog = () => {
    setIsAboutUsOpen(true);
  };

  const closeAboutUsDialog = () => {
    setIsAboutUsOpen(false);
  };

  const openContactUsDialog = () => {
    setIsContactUsOpen(true); // Open the Contact Us dialog
  };

  const closeContactUsDialog = () => {
    setIsContactUsOpen(false); // Close the Contact Us dialog
  };


  return (
    <div className="footer">
      <div className="footer-content">
        <a href="https://www.facebook.com/forloveofwriters/" target="_blank">
          <i className="sidebarIcon-home fab fa-facebook-square"></i>
        </a>
        <span className="pipe">&#124;</span>

        <a href="https://www.instagram.com/4loveofwriters/" target="_blank">
          <i className="sidebarIcon-home fab fa-instagram-square"></i>
        </a>
        <span className="pipe">&#124;</span>

        <a href="https://www.linkedin.com/company/forloveofwriters/" target="_blank">
          <i className="sidebarIcon-home fab fa-linkedin-square"></i>
        </a>
      </div>

      <div className="centerItems">
        <Link to="#" onClick={openAboutUsDialog}>
          ABOUT US
        </Link>
        {/* <Link to="/generalDisclaimer">General Disclaimer</Link>
        <Link to="/codeofConduct">Code of Conduct</Link>
        <Link to="/cookiePolicy">Cookie Policy</Link> */}
        <Link to="#" onClick={openContactUsDialog}>
          CONTACT US
        </Link>
        <Link to="/privacyPolicy">PRIVACY POLICY</Link>
        <Link to="/tos">TERMS OF SERVICE</Link>
       
      </div>

      <div className="copyright">
        <p style={{paddingBottom:"10px"}}>&copy; 2024, For Love of Writers</p>
      </div>

      {/* About Us Dialog */}
      <Dialog
        isOpen={isAboutUsOpen}
        onClose={closeAboutUsDialog}
        title="About Us"
      >
        <p>
          ‘For Love of Writers’ is a special community of writers who live a life of passion for the written word. Writing is as diverse as humanity. Each person on this little blue planet has a unique voice waiting to be heard. For Love of Writers is an outlet for that voice. We exist for the singular purpose of providing a voice for the passionate writer. Let us hear your voice. Be heard.
        </p>
      </Dialog>

      <Dialog
        isOpen={isContactUsOpen}
        onClose={closeContactUsDialog}
        title="Contact Us"
      >
        <p>If you encounter any problems, please contact us at: 
          
          <br></br>support@forloveofwriters.com</p>
        
      </Dialog>

      </div>
  );
}
