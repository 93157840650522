import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { Context } from "./context/Context";
import { Navigate } from "react-router-dom";
import TopBar from "./components/topbar/TopBar";
import Footer from "./components/footer/Footer";
import Articles from "./pages/articles/Articles";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Feedback from "./pages/feedback/Feedback";
import Membership from "./pages/membership/Membership";
import Profile from "./pages/profile/Profile";
import Write from "./pages/write/Write";
import Settings from "./pages/settings/Settings";
import Reading from "./pages/reading/Reading";
import Search from "./pages/search/Search";
import Trending from "./pages/trending/Trending";
import Plans from "./pages/plans/Plans";
import Subscribe from "./pages/subscribe/Subscribe";
import Preference from "./pages/preference/Preference";
import Followers from "./pages/followers/Followers";
import Followings from "./pages/followings/Followings";
import Username from "./pages/username/Username";
import Post from "./pages/post/Post";
import Forgotpassword from "./pages/forgotPassword/ForgotPassword";
import LegalPage from "./pages/legalPage/LegalPage";

import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header/Header";

import Authors from "./pages/authors/Authors";
import Categories from "./pages/categories/Categories";
import Author from "./pages/author/Author";
import Category from "./pages/category/Category";
import Faq from "./pages/faq/Faq";

function App() {
  const { user } = useContext(Context);
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/articles"
          element={
            <>
              <Header />
              <Articles />
              <Footer />
            </>
          }
        />
        <Route
          path="/subscribe"
          element={
            <>
              <Subscribe />
              <Footer />{" "}
            </>
          }
        />
        <Route
          path="/preference"
          element={
            <>
              <Header />
              <Preference />
              <Footer />
            </>
          }
        />
        <Route
          path="/register"
          element={
            <>
              <Header />
              <Register />
              <Footer />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <Header />
              <Login />
              <Footer />
            </>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <>
              <Forgotpassword />
              <Footer />
            </>
          }
        />
        <Route
          path="/username"
          element={
            <>
              <Username />
              <Footer />
            </>
          }
        />
        <Route
          path="/faq"
          element={
            <Navigate to="/" replace />
          }
        />
        <Route
          path="/membership"
          element={
            <>
              <Membership />
              <Footer />
            </>
          }
        />
        <Route
          path="/search"
          element={
            <>
              <Header />
              <Search />
              <Footer />
            </>
          }
        />
        <Route
          path="/feedback"
          element={
            <>
              <Header />
              <Feedback />
              <Footer />
            </>
          }
        />
        <Route
          path="/write"
          element={
            // user ? (
            <>
              <Header />
              <Write />
              <Footer />
            </>
            // ) : (
            //   <>
            //     <Register />
            //     <Footer />
            //   </>
            // )
          }
        />
        <Route
          path="/settings"
          element={
            user ? (
              <>
                <Header />
                <Settings />
                <Footer />
              </>
            ) : (
              <>
                <Register />
                <Footer />
              </>
            )
          }
        />
        <Route
          path="/profile"
          element={
            user ? (
              <>
                <Header />
                <Profile />
                <Footer />
              </>
            ) : (
              <>
                <Register />
                <Footer />
              </>
            )
          }
        />
        <Route
          path="/reading"
          element={
            user ? (
              <>
                <Header />
                <Reading />
                <Footer />
              </>
            ) : (
              <>
                <Register />
                <Footer />
              </>
            )
          }
        />
        <Route
          path="/followings"
          element={
            user ? (
              <>
                <Header />
                <Followings />
                <Footer />
              </>
            ) : (
              <>
                <Register />
                <Footer />
              </>
            )
          }
        />
        <Route
          path="/followers"
          element={
            user ? (
              <>
                <Header />
                <Followers />
                <Footer />
              </>
            ) : (
              <>
                <Register />
                <Footer />
              </>
            )
          }
        />
        <Route
          path="/:postSlug"
          element={
            <>
              <Header />
              <Post />
              <Footer />
            </>
          }
        />
        <Route
          path="/author/:authorId"
          element={
            <>
              <Header />
              <Author />
              <Footer />
            </>
          }
        />
        <Route
          path="/trending"
          element={
            <>
              <Header />
              <Trending />
              <Footer />
            </>
          }
        />
        <Route
          path="/plans"
          element={
            <>
              <Plans />
              <Footer />
            </>
          }
        />
        <Route
          path="/authors"
          element={
            <>
              <Header />
              <Authors />
              <Footer />
            </>
          }
        />
        <Route
          path="/categories"
          element={
            <>
              <Header />
              <Categories />
              <Footer />
            </>
          }
        />
        <Route
          path="/category/:catId"
          element={
            <>
              {/* <Header /> */}
              <Category />
              <Footer />
            </>
          }
        />
        <Route
          path="/tos"
          element={
            <>
              <LegalPage fileName="TermsofService.html" /> <Footer />
            </>
          }
        ></Route>
        <Route
          path="/privacyPolicy"
          element={
            <>
              <LegalPage fileName="PrivacyPolicy.html" /> <Footer />
            </>
          }
        ></Route>
        <Route
          path="/cookiePolicy"
          element={
            <>
              <LegalPage fileName="CookiePolicy.html" /> <Footer />
            </>
          }
        ></Route>
        <Route
          path="/codeofConduct"
          element={
            <>
              <LegalPage fileName="CodeofConduct.html" /> <Footer />
            </>
          }
        ></Route>
        <Route
          path="/generalDisclaimer"
          element={
            <>
              <LegalPage fileName="GeneralDisclaimer.html" /> <Footer />
            </>
          }
        ></Route>
      </Routes>
      {/* <Footer /> */}
      <ToastContainer />
    </Router>
  );
}

export default App;
