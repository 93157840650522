import React, { useState, useContext, useEffect } from "react";
import "./subscribe.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import Header from "../../components/header/Header";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleXmark, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Subscribe() {
  const { user, token, dispatch } = useContext(Context);
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const [subscription, setSubscription] = useState({});

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const getSubscription = async () => {
      if (!user || !user.userId) return;
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const planType = res.data.subscription.plan;
        const subscriptionId = res.data.subscription.subscriptionId;
        setSubscription({ plan: planType, subscriptionId: subscriptionId });
      } catch (err) {
        console.error("Error getting Subscription! " + err);
        toast.error("Error Fetching Subscription Details!");
      }
    };
     if (user) {
       getSubscription();
     }
  }, [user]);


  const handleSubscribe = async (plan) => {
    if (!user) {
      toast.info("You Need To Login First!");
      navigate("/login");
    } else {
      try {
        const userId = user ? user._id : null;
        const currentPlan = subscription.plan;
        if (currentPlan !== "plan") {
          toast.info("Manage your active subscription in your profile!");
        }
        const res = await axios.post("/stripe/create-checkout-session", {
          plan,
          userId,
        }, {headers: {'Authorization':`Bearer ${token}`}});
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const session = await res.data;
        window.location.href = session.url;
      } catch (error) {
        toast.error("Error Occured During Checkout!");
        console.error("Error creating checkout session: ", error);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="center-text">
        <h1 style={{ fontSize: "40px", paddingBottom:"20px" }}>
          Your Next Great Read Starts Here!
        </h1>
      </div>
      <div className="center-text">
        <p style={{ fontSize: "25px", marginBottom: "5rem" }}>
          Choose a plan and explore our entire library of ad-free content.
        </p>
      </div>

      <section className="card-container-subscribe">
        <div className="card-subscribe">
          

          <div style={{ paddingLeft: "25px" }}>

          <h2
            style={{
              fontSize: "25px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            MONTHLY PLAN
          </h2>
            <p style={{ fontSize: "40px", fontWeight: 700 }}>
              $10
              <span style={{ fontSize: "20px", fontWeight: 400 }}>&nbsp;CAD</span>
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "20px",
              }}
            >
              /Month
            </p>
            <p style={{ fontSize: "20px", fontWeight: 400 }}>$2.50 per week</p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "30px",
              }}
            >
              Billed Monthly
            </p>
          </div>

          <div style={{ display: "flex" }}>
            {/* <a href="/payment"> */}
            <button
              style={{
                height: "63px",
                width: "277px",
                fontSize: "28px",
                fontWeight: "700",
              }}
              onClick={() => handleSubscribe("monthly")}
            >
              Subscribe Now!
            </button>
            {/* </a> */}
          </div>
        </div>

        <div className="card-subscribe">
          
          <div style={{ paddingLeft: "25px", position: "relative" }}>
          <div className="card-free-months" style={{
                    position: "absolute",
                    top: "-50px",
                    right: "-90px",
                    width: "219px",
                    height: "61px",
                    backgroundColor: "#C9CDD3", // Example color, adjust as needed
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "700",
                    borderRadius: "10px",
                    fontSize:"24px",
                    fontWeight:"700"
                    }}>
                    3 MONTHS FREE!
                    </div>   
          <h2
            style={{
              fontSize: "25px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            ANNUAL PLAN
          </h2>
            <p style={{ fontSize: "40px", fontWeight: 700 }}>
              $90
              <span style={{ fontSize: "20px", fontWeight: 400 }}>&nbsp;CAD</span>
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "20px",
              }}
            >
              /Year
            </p>
            <p style={{ fontSize: "20px", fontWeight: 400 }}>$1.75 per week</p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "30px",
              }}
            >
              Billed annually
            </p>
          </div>
          <div style={{ display: "flex" }}>
            {/* <a href="/payment"> */}
            <button
              style={{
                height: "63px",
                width: "277px",
                fontSize: "28px",
                fontWeight: "700",
              }}
              onClick={() => handleSubscribe("annual")}
            >
              Subscribe Now!
            </button>
            {/* </a> */}
          </div>
        </div>
      </section>
    </>
  );
}

function SearchBar({ onClose }) {
  return (
    <div className="search-overlay">
      <div className="search-bar-container">
        <form className="searchForm1-search">
          <div className="input-container-search">
            <input
              type="text"
              placeholder="Entering..."
              style={{
                width: "679px",
                height: "52px",
                borderRadius: "10px",
                border: "0.5px solid lightgray",
                paddingRight: "40px",
                position: "relative", // changed from absolute to relative
              }}
              onChange={(e) => {
                // handle search input
              }}
            />
            <button className="searchSubmit1" type="submit" style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "20px",
                color: "black"
              }}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
            
          </div>
          <button className="close-search" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        </form>
        
      </div>
    </div>
  );
}
