import React, { useState, useEffect, useContext, useRef } from "react";
import UserProfile from "../../components/profile/userProfile/UserProfile";
import AuthorPreference from "../../components/profile/authorPreference/AuthorPreference";
import GenrePreference from "../../components/profile/genrePreference/GenrePreference";
import ManageSubscription from "../../components/profile/manageSubscription/ManageSubscription";
import NotSubscribed from "../../components/profile/notSubscribed/NotSubscribed";
import { Context } from "../../context/Context";
import axios from "axios";
import "./profile.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Profile = () => {
  const { user, token, dispatch } = useContext(Context);

  const [activeSection, setActiveSection] = useState("user-info");
  const [selectedImage, setSelectedImage] = useState(null);
  const [bio, setBio] = useState("User's Bio");
  const [editingBio, setEditingBio] = useState(false);
  const [tempBio, setTempBio] = useState("");
  const [userData, setUserData] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const bioRef = useRef(null);
  const PF = process.env.REACT_APP_AWS_S3_IMAGE_URL;

  const roles = {
    1: "Reader",
    2: "Writer",
    3: "Editor",
    4: "Admin",
    5: "SuperAdmin",
  };

  useEffect(() => {
    const getUser = async () => {
      if (!user?.userId) return; // Prevent running if user is null
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status === 200) {
          setUserData(res.data);
          setBio(res.data.bio || ""); // Handle case where bio is null
        } else {
          toast.info(res.data.msg);
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
        toast.error("Error Fetching User!");
      }
    };

    if (user && token) {
      getUser();
    }
  }, [user?.userId, token]);

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        if (res.data.subscription.plan !== "plan") {
          setSubscriptionStatus(true);
        } else {
          setSubscriptionStatus(false);
        }
      } catch (err) {
        console.error("Error getting Subscription! " + err);
        toast.error("Error Fetching Subscription Details!");
      }
    };

    if (user && token) {
      getSubscription();
    }
  }, [user, token]);

  useEffect(() => {
    const verifyPayment = async () => {
      const sessionId = searchParams.get("session_id");

      if (sessionId) {
        try {
          const res = await axios.get(
            `/stripe/verify-payment-session?session_id=${sessionId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          if (res.status === 200 && res.data.success) {
            toast.success("Payment verified successfully!");
            setSubscriptionStatus(true);
            navigate("/profile");
          } else {
            toast.error("Payment verification failed!");
          }
        } catch (error) {
          console.error("Error verifying payment: ", error);
          toast.error("Error verifying payment!");
        }
      }
    };

    verifyPayment();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().slice(0, 10);
  };

  const handleSaveUserData = async (formData) => {
    dispatch({ type: "UPDATE_START" });

    try {
      const res = await axios.put(`/users/${user._id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status !== 200) {
        toast.info(res.data.msg);
      }
      dispatch({ type: "UPDATE_SUCCESS", payload: res.data });
      toast.success("Updated!");
    } catch (error) {
      dispatch({ type: "UPDATE_FAILURE" });
      console.log("Error updating user data: ", error);
      toast.error("Error Saving Preferences!");
    }
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // Prevent error if no file is selected
    
    console.log("User selected file:", file); //********debugging********
    
    const reader = new FileReader();

    reader.onload = (e) => {
      
      console.log("Image loaded for preview:", e.target.result); //********debugging********
      
      setSelectedImage(e.target.result);
    };

    reader.readAsDataURL(file);
    setFile(file);

    // Call the API to upload the file
      try {
        const data = new FormData();
        data.append("file", file);

        console.log("Sending file to backend..."); //********debugging********

        const uploadResponse = await axios.post("/upload", data, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log("Upload response:", uploadResponse.data); //********debugging********
        
        const newImageUrl = uploadResponse.data.file.startsWith("http")
          ? uploadResponse.data.file
          : `${PF.replace(/\/$/, "")}/${uploadResponse.data.file}`;

        console.log("New profile image URL:", newImageUrl); //********debugging********

        console.log("Updating user profile with new image..."); //********debugging********
        
        await axios.put(
          `/users/${user._id}`,
          { profilePic: newImageUrl },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setUserData((prevData) => ({
          ...prevData,
          profilePic: newImageUrl,
        }));

        setSelectedImage(newImageUrl); // Ensure UI updates correctly
        toast.success("Profile picture updated!");

      } catch (error) {
        console.error("Error Uploading Image", error);
        toast.error("Error uploading image!");
      }
  };

  const handleEditBio = () => {
    setTempBio(bio || "");  // Prevents null values
    setEditingBio(true);
  };

  useEffect(() => {
    if (editingBio && bioRef.current) {
      bioRef.current.select();  // Ensures the text is selected only when ready
    }
  }, [editingBio]);

  const handleSaveBio = async () => {

    console.log("Saving bio... New Bio:", tempBio);
    
      try {
        const res = await axios.put(
          `/users/${user._id}`,
          { bio: tempBio },
          { headers: { Authorization: `Bearer ${token}` } }
        );

    console.log("Bio update response:", res.data);

        if ([200, 201, 204].includes(res.status)) {

          console.log("Bio update successful. Updating state...");
          
          setBio(tempBio);
          setEditingBio(false);
          toast.success("Bio updated successfully!");
        } else {

          console.warn("Unexpected response status:", res.status);
          
          toast.info("Something went wrong while updating bio.");
        }
      } catch (error) {
        
        console.error("Error updating bio:", error.response ? error.response.data : error);
        
        toast.error("Error Updating Bio!");
      }
  };

  const handleChangeTempBio = (e) => {
    setTempBio(e.target.value.trimStart());
  };
  
  useEffect(() => {
    const hamburgerIcon = document.querySelector(".menuIcon");
    const mobileMenu = document.querySelector(".mobile-menu");
    const closeIcon = document.querySelector(".closeIcon");

    const handleHamburgerClick = () => {
      mobileMenu.classList.toggle("hidden");
    };

    const handleCloseIconClick = () => {
      mobileMenu.classList.add("hidden");
    };

    if (hamburgerIcon) {
      hamburgerIcon.addEventListener("click", handleHamburgerClick);
    }

    if (closeIcon) {
      closeIcon.addEventListener("click", handleCloseIconClick);
    }

    return () => {
      if (hamburgerIcon) {
        hamburgerIcon.removeEventListener("click", handleHamburgerClick);
      }
      if (closeIcon) {
        closeIcon.removeEventListener("click", handleCloseIconClick);
      }
    };
  }, []);

  const handleSubscriptionCancelled = (newStatus) => {
    setSubscriptionStatus(newStatus);
    navigate("/profile");
  };

  return (
    <div className="container-profile">
      <div className="cards-profile">
        <div className="left-card-profile">
          <div className="user-info-profile">
            <h2>{userData?.user_name || "User's Full name"}</h2>
            <p>{userData?.username || "User's Username"}</p>
            <p>{userData?.pronouns || ""}</p>
            {userData?.role > 1 && <p>{roles[userData?.role]}</p>}
            <div className="profile-pic">
              <label className="-label" htmlFor="file">
                <span className="glyphicon glyphicon-camera"></span>
                <span>Change Image</span>
              </label>
              <input id="file" type="file" onChange={handleImageChange} />
              {selectedImage ? (
                <img src={selectedImage} alt="Selected Profile" width="200" />
              ) : (
                <img
                  src={userData?.profilePic}
                  alt="Profile"
                  id="output"
                  width="200"
                />
              )}
            </div>
            <div className="input-container">
              <div style={{ position: "relative" }}>
                <textarea
                  id="editableInput"
                  ref={bioRef}
                  value={editingBio ? tempBio : bio}
                  placeholder="Tell us a little about yourself..."
                  onChange={handleChangeTempBio}
                  disabled={!editingBio}
                ></textarea>
                {editingBio ? (
                  <span
                    className="save-icon"
                    style={{
                      position: "absolute",
                      bottom: "8px",
                      right: "8px",
                      cursor: "pointer",
                    }}
                    onClick={handleSaveBio}
                  >
                    &#10003;
                  </span>
                ) : (
                  <span className="pencil-icon" onClick={handleEditBio}>
                    &#9998;
                  </span>
                )}
              </div>
            </div>
            <p style={{ marginTop: "10px" }}>
              Member since:<b> {formatDate(user.createdAt)}</b>
            </p>
          </div>
        </div>
        <div className="right-card-profile">
          <div className="black-section">
            <h2
              className="edit-profile"
              style={{ fontSize: "32px", fontWeight: "700", marginTop: "15px" }}
            >
              Edit Profile
            </h2>
            <ul className="profile-sections">
              <li>
                <button
                  onClick={() => handleButtonClick("user-info")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight: activeSection === "user-info" ? "" : "normal",
                  }}
                >
                  User Info
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleButtonClick("author-preference")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight:
                      activeSection === "author-preference" ? "" : "normal",
                  }}
                >
                  Writer Preferences
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleButtonClick("genre-preference")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight:
                      activeSection === "genre-preference" ? "" : "normal",
                  }}
                >
                  Genre Preferences
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleButtonClick("manage-subscription")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight:
                      activeSection === "manage-subscription" ? "" : "normal",
                  }}
                >
                  Manage Subscription
                </button>
              </li>
            </ul>
          </div>
          <div
            className="form-section-profile2"
            style={{
              display: activeSection === "user-info" ? "block" : "none",
            }}
          >
            <UserProfile onSave={handleSaveUserData} />
          </div>
          <div
            className="form-section-profile2"
            style={{
              display: activeSection === "author-preference" ? "block" : "none",
            }}
          >
            <AuthorPreference />
          </div>
          <div
            className="form-section-profile2"
            style={{
              display: activeSection === "genre-preference" ? "block" : "none",
            }}
          >
            <GenrePreference />
          </div>
          <div
            className="form-section-profile2"
            style={{
              display: activeSection === "manage-subscription" ? "block" : "none",
            }}
          >
            {subscriptionStatus ? (
              <ManageSubscription onSubscriptionCancelled={handleSubscriptionCancelled} />
            ) : (
              <NotSubscribed />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
